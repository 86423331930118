<template>
	<div class="content d-flex align-center justify-center">
		<div class="logo">
			<h1><metaPolicy isWhite /></h1>
		</div>
		<v-form ref="form" lazy-validation>
			<div v-show="!credential">
				<div class="actions">
					<v-btn href="/security/fbSSO" style="margin-top:10px;">
						Log in using Meta SSO
					</v-btn>
				</div>

				<div @click="credential = !credential" class="or clickable" style="cursor: pointer">login in with non-Meta credentials</div>
			</div>
			<div v-if="credential">
				<div class="or">Your credentials</div>
				<v-text-field
					v-model="username"
					color="metaGray"
					label="User name"
					:rules="[(v) => !!v || 'User name is mandatory']"
					required
				></v-text-field>
				<v-text-field
					v-model="password"
					color="metaGray"
					label="Password"
					type="password"
					:rules="[(v) => !!v || 'Password is mandatory']"
					required
				></v-text-field>
				<v-alert dense outlined type="error" v-if="error">
					{{ error }}
				</v-alert>
				<div class="actions">
					<span @click="credential = false; error = ''" class="back clickable">
						Back
					</span>
					<v-btn @click="tryToLogin" :disabled="!this.username || !this.password">
						Log in
					</v-btn>
				</div>
			</div>
		</v-form>
		<div class="disclaimer" v-if="disclaimer === true">
			Issues Logging In? Contact us at <a href="mailto:datadashboard@fb.com">datadashboard@fb.com</a>
			<span class="close" @click="closeDisclaimer('login')"><span>x</span></span>
		</div>
	</div>
</template>


<script>

import metaPolicy from '@/components/logos/meta-policy';
import Ga from '@/mixins/ga';
import GaEvent from '@/constants/ga-event';

const api = require('@/utils/api');

import { mapMutations, mapState } from 'vuex';

export default {

	name: 'Login',

	components: {
		metaPolicy
	},

	mixins: [Ga],

	data () {
		return {
			credential: false,
			username: '',
			password: '',
			error: ''
		};
	},

	methods: {
		...mapMutations('general', ['closeDisclaimer']),
		async tryToLogin () {
			const result = await api.login(this.username, this.password);

			if (result === 'OK') {
				this.sendGaEvent(GaEvent.login, GaEvent.connection, this.username);
				this.$router.push({ name: 'Home' });
			} else if (result) {
				this.error = result;
			} else {
				this.error = 'Unknown internal error';
			}
		}
	},

	computed: {
		...mapState({
			disclaimer: (state) => state.general.disclaimers.login
		})
	}

};

</script>

<style lang="scss" scoped>

@import "@/scss/_colors.scss";

.content {
	height: 100%;
	background-color: $gray-800;
	.logo {
		position: absolute;
    top: 32px;
    left: 32px;
    display: flex;
    align-items: baseline;
		h1 {
			> svg {
				height: 35px;
				fill: #fff;
			}
		}
		small {
			color: #fff;
			opacity: 0.7;
			font-size: 15px;
			text-decoration: underline;
			padding-left: 20px;
		}
	}
}

.or {
	margin: 16px auto;
	padding: 4px 0;
	border-top: 1px dotted #b9bec9;
	border-bottom: 1px dotted #b9bec9;
	max-width: 80%;
}

.clickable {
	cursor: pointer;
}
.clickable:hover {
	text-decoration: underline;
}

.actions {
	margin: 10px 0 20px;

	.back {
		font-size: 12px;
		margin-right: 12px;
	}
}

form {
	background-color: #fff;
	width: 330px;
	padding: 40px;
	text-align: center;
}


.btn-meta {
    background: #3B5998 !important;
    color: #fff !important;
    &:hover {
        color: #fff;
        opacity: 0.8;
    }
}

</style>
